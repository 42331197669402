<script>
import { debounce } from 'lodash'

export default {
  name: 'Autocomplete',
  components: {
    Icon: () => import('@/components/general/Icon'),
    Loading: () => import('@/components/general/Loading'),
    ValidationMessage: () => import('@/components/general/ValidationMessage')
  },

  props: {
    placeholder: {
      type: String,
      default: null
    },

    label: {
      type: String,
      default: null
    },

    floatingLabel: {
      type: Boolean,
      default: true
    },

    disabled: {
      type: Boolean,
      default: false
    },

    readonly: {
      type: Boolean,
      default: false
    },

    value: {
      type: Array,
      default: false
    },

    validation: {
      type: Object,
      default: function () {
        return {}
      }
    },

    hint: {
      type: String,
      default: null
    },

    appendIcon: {
      type: String,
      default: null
    },

    dark: {
      type: Boolean,
      default: false
    },

    items: {
      type: Array,
      default: () => { return [] }
    },

    optionProperty: {
      type: String,
      default: null
    },

    hideDetails: {
      type: Boolean,
      default: false
    },

    maxValue: {
      type: Number,
      default: 1
    },

    async: {
      type: Boolean,
      default: false
    },

    showItensOnFocus: {
      type: Boolean,
      default: false
    },

    underDescription: {
      type: String,
      default: null
    },

    enableEmitInside: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      isActive: false,
      isFocused: false,
      appendSize: 0,
      searchValue: '',
      options: [],
      hoveredIndex: 0,
      mutableValue: [],
      emitInside: false,
      loading: false
    }
  },

  computed: {
    showEmptyResult () {
      const notFound = this.mutableValue.length > 0 && this.options.filter(item => item.selected === false).length === 0

      return !this.loading && (this.options.length === 0 || notFound)
    }
  },

  watch: {
    loading () {
      if (this.loading) {
        this.options = []
        this.isActive = this.loading
      }
    },

    items () {
      this.hoveredIndex = 0
      this.isActive = true

      this.debounceEvent(() => {
        this.loading = false
      }, 2000)

      this.options = this.items.map((item, index) => {
        item.selected = false
        item.onlySearchable = item.onlySearchable || false

        if (index === this.items.length - 1) {
          this.loading = false
        }

        return item
      })
    },

    value () {
      if (!this.emitInside || this.enableEmitInside) {
        this.checkValue()
      }

      this.emitInside = false
    },

    mutableValue () {
      this.emitInside = true
      this.$emit('input', this.mutableValue.length === 0 ? null : this.mutableValue)
    }
  },

  mounted () {
    this.checkValue()
  },

  methods: {
    toggleFocus (should = null) {
      if (should !== null) {
        this.isFocused = should === 'focus'

        if (this.isFocused) {
          if (!this.async || this.showItensOnFocus) {
            this.search(this.searchValue, '')
          }

          this.$refs.input.focus()
        } else if (!this.isActive) {
          this.$refs.input.blur()
        }
      }
    },

    search (value) {
      this.searchValue = value
      this.loading = true

      if (this.async) {
        this.emitSearch()
        this.hoveredIndex = null
      } else {
        this.$emit('internalSearch', this.searchValue)
        this.isActive = true

        this.options = this.items
          .filter(option => this.getValue(option)
            .toString()
            .toLowerCase()
            .indexOf(this.searchValue.toLowerCase()) >= 0)
          .filter(option => !this.mutableValue.includes(option))
      }
    },

    emitSearch: debounce(function () {
      this.mutableValue = []
      this.$emit('search', this.searchValue)
    }, 1000),

    setSelected (option) {
      this.isActive = false
      this.hoveredIndex = null
      option.selected = true
      this.mutableValue.push(option)
      this.searchValue = this.maxValue !== 1 ? '' : this.getValue(option)

      if (this.async) {
        this.options = []
      } else if (this.maxValue > 1 || this.maxValue === -1) {
        this.$refs.input.focus()
      }
    },

    keyArrows (direction) {
      const sum = direction === 'down' ? 1 : -1

      if (this.isActive && this.options.length) {
        let index = this.hoveredIndex + sum

        index = index > this.options.length - 1 ? 0 : index
        index = index < 0 ? this.options.length - 1 : index
        this.hoveredIndex = index

        if (this.options[this.hoveredIndex].selected && this.options.filter(item => item.selected === false).length) {
          this.keyArrows(direction)
        }
      } else if (this.options.length) {
        this.isActive = true
        this.hoveredIndex = 0

        if (this.options[this.hoveredIndex].selected && this.options.filter(item => item.selected === false).length) {
          this.keyArrows('down')
        }
      }

      if (this.hoveredIndex > 0) {
        const target = this.$refs[`item-${this.hoveredIndex}`][0]

        this.$refs.scrollingContainer.scrollTop = target.offsetTop
      }
    },

    enterPressed () {
      if (this.hoveredIndex === null) return
      this.setSelected(this.options[this.hoveredIndex])
    },

    deletePressed () {
      if (this.mutableValue.length) {
        if ((this.maxValue !== 1 && this.searchValue.length === 0) || this.maxValue === 1) {
          this.mutableValue[this.mutableValue.length - 1].selected = false
          this.mutableValue.splice(this.mutableValue.length - 1, 1)
        }
      }
    },

    outside () {
      if (!this.isFocused) {
        this.isActive = false
      }
    },

    removeTag (option, index) {
      option.selected = false
      this.mutableValue.splice(index, 1)
      this.$refs.input.focus()
    },

    checkValue () {
      if (!this.async) {
        this.options = this.items.map(item => {
          item.selected = Boolean(this.value && this.value.find(itemVal => itemVal.id === item.id))

          return item
        })
      }

      this.mutableValue = this.value === null ? [] : this.value

      if (this.value === null) {
        this.searchValue = ''
      }

      if (this.disabled && this.value) {
        this.mutableValue = this.value
        this.searchValue = this.value.label
      }
    },

    getValue (option, highlight = false) {
      const value = (typeof option === 'object') ? option[this.optionProperty] : option
      const regex = new RegExp(`(${this.searchValue})`, 'i')

      return highlight ? value.replace(regex, '<b>$1</b>') : value
    }
  }
}
</script>

<template>
  <div
    ref="formItem"
    :class="{
      'has-error': validation.$error,
      'has-value': value !== null || searchValue || placeholder,
      'has-focus': isFocused || isActive,
      'is-disabled': disabled,
      'has-floating-label': floatingLabel,
      'theme-dark': dark,
      'hide-details': hideDetails,
      'is-multiple': maxValue !== 1
    }"
    class="form-item is-autocomplete"
  >
    <label
      v-if="label"
      class="form-label"
      :for="'input' + _uid"
    >{{ label }}</label>
    <div class="form-input-wrapper">
      <template v-if="maxValue !== 1">
        <template v-for="(tag, index) in mutableValue">
          <a
            :key="index"
            href="#"
            class="tag-item"
            @click.prevent="removeTag(tag, index)"
          >
            <span class="text">{{ optionProperty ? tag[optionProperty] : tag }}</span>
            <Icon name="close" />
          </a>
        </template>
      </template>
      <input
        :id="'input' + _uid"
        ref="input"
        class="form-input"
        spellcheck="false"
        type="text"
        autocomplete="off"
        :placeholder="placeholder"
        :disabled="disabled"
        :readonly="readonly"
        :value="searchValue"
        @input="search($event.target.value, $event)"
        @blur.stop="toggleFocus('blur')"
        @focus="toggleFocus('focus')"
        @keydown.up.prevent="keyArrows('up')"
        @keydown.down.prevent="keyArrows('down')"
        @keydown.enter.prevent="enterPressed()"
        @keydown.delete="deletePressed()"
      >
      <div
        ref="append"
        class="form-input-append"
        @click="toggleFocus('blur')"
      >
        <Icon
          v-if="appendIcon"
          :name="appendIcon"
          wrapper
        />
        <Icon
          v-if="!appendIcon && !async"
          name="arrow_drop_down"
        />
      </div>
      <ul
        v-show="isActive && maxValue !== mutableValue.length"
        ref="scrollingContainer"
        v-click-outside="outside"
        class="form-autocomplete-options"
      >
        <template v-for="(option, index) in options">
          <li
            v-if="!option.selected && (
              (option.onlySearchable === true && searchValue && searchValue.length > 0) ||
              option.onlySearchable === false)"
            :ref="`item-${index}`"
            :key="`autocomplete-item-${index}`"
            class="form-autocomplete-options-item"
            :class="{'is-hovered': hoveredIndex == index}"
            @click.stop="setSelected(option)"
            @mouseenter="hoveredIndex = index"
          >
            <slot
              :option="option"
              :index="index"
            >
              <span v-html="getValue(option, true)" />
            </slot>
          </li>
        </template>
        <li
          v-if="showEmptyResult && !loading"
          class="form-autocomplete-options-item is-disabled"
        >
          {{ $t('global:autocomplete.empty.search') }}
        </li>
        <li
          v-if="loading"
          class="form-autocomplete-options-item is-loading"
        >
          <Loading v-if="loading" />
        </li>
      </ul>
    </div>
    <div class="form-input-details">
      <p
        v-if="underDescription"
        class="form-input-subtext"
      >
        {{ underDescription }}
      </p>
      <span
        v-if="hint && !validation.$error"
        class="form-input-hint"
      >{{ hint }}</span>
      <ValidationMessage :validation="validation" />
    </div>
  </div>
</template>

<style src="@/assets/styles/themes/default/form.css"></style>

<style src="@/assets/styles/themes/default/autocomplete.css"></style>
